<template>
  <div>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      v-model="editorData"
      @input="onEditorInput"
      :disabled="disabled"
    />
  </div>
</template>

<script>
  import "ckeditor5/ckeditor5.css";

  import CKEditor from "@ckeditor/ckeditor5-vue2";

  import CKEditorMentionCustomization from "@/config/CKEditorMentionCustomization";

  import {
    BlockQuote,
    Bold,
    ClassicEditor,
    Code,
    Essentials,
    Heading,
    HorizontalLine,
    HtmlEmbed,
    Image,
    ImageCaption,
    ImageCaptionEditing,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    List,
    Mention,
    Paragraph,
    PasteFromOffice,
    SimpleUploadAdapter,
    Strikethrough,
    Underline,
  } from "./ckeditor";

  export default {
    name: "CustomEditor",
    components: {
      ckeditor: CKEditor.component,
    },
    props: {
      value: {
        required: true,
      },
      index: {
        required: false,
      },
      users: {
        type: Array,
        default: () => [],
      },
      uploadImage: {
        required: false,
        default: true,
      },
      disabled: {
        required: false,
        default: false,
      },
    },
    data() {
      return {
        editor: ClassicEditor,
        editorConfig: {
          plugins: [
            Essentials,
            Heading,
            Bold,
            Italic,
            Link,
            Paragraph,
            Underline,
            Strikethrough,
            Code,
            List,
            ImageCaptionEditing,
            HorizontalLine,
            BlockQuote,
            Mention,
            CKEditorMentionCustomization,
            Image,
            ImageToolbar,
            ImageCaption,
            ImageStyle,
            ImageResize,
            LinkImage,
            HtmlEmbed,
            SimpleUploadAdapter,
            ImageUpload,
            PasteFromOffice,
          ],
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "toggleImageCaption",
              "strikethrough",
              "code",
              "blockquote",
              "numberedList",
              "undo",
              "redo",
              "bulletedList",
              "|",
              "link",
              "horizontalLine",
              "htmlEmbed",
            ],
          },
          mention: {
            feeds: [
              {
                marker: "@",
                feed: this.mentionList,
                minimumCharacters: 1,
                itemRenderer: this.customUserRenderer,
              },
            ],
          },
          image: {
            styles: ["alignLeft", "alignCenter", "alignRight"],
            toolbar: [
              "imageStyle:alignLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignRight",
              "toggleImageCaption",
            ],
          },
          htmlEmbed: {
            showPreviews: true,
          },
          simpleUpload: {
            uploadUrl: `${process.env.VUE_APP_API_GATEWAY_HOST}/assets/upload/editor`,
            headers: {
              Authorization: `Bearer ${this.$store.getters["authorization/currentUserAccessToken"]}`,
            },
          },
        },
        editorData: this.value || "",
        mentions: [],
      };
    },
    created() {
      this.setUploadImageAttribute();
    },
    watch: {
      value(newValue) {
        this.editorData = newValue;
      },
      editorData(newValue) {
        let ids = [];

        const mentions = [];
        const regex = RegExp('data-mention-id="([^"]*)"', "g");

        // eslint-disable-next-line no-cond-assign
        while ((ids = regex.exec(newValue)) !== null) {
          const userIds = ids[1].split(",");

          userIds.forEach((userId) => {
            const id = parseInt(userId, 10);

            if (id) {
              if (!this.mentions.includes(id)) {
                mentions.push(id);
              }
            }
          });
        }

        this.$emit("updateMentions", mentions, this.index);
      },
    },
    computed: {
      customUserList() {
        return Object.keys(this.users).map((key) => {
          const user = this.users[key];

          return { id: `@${user.fullName}`, userId: user.id, name: user.fullName };
        });
      },
    },
    methods: {
      onEditorInput() {
        this.$emit("input", this.editorData);
      },
      mentionList(queryText) {
        const isItemMatching = (item) => {
          const searchString = queryText.toLowerCase();

          return item.name.toLowerCase().includes(searchString);
        };

        return new Promise((resolve) => {
          setTimeout(() => {
            const itemsToDisplay = this.customUserList.filter(isItemMatching).slice(0, 10);

            resolve(itemsToDisplay);
          }, 100);
        });
      },
      customUserRenderer(item) {
        const itemElement = document.createElement("span");

        itemElement.classList.add("custom-item");
        itemElement.id = `mention-list-item-id-${item.userId}`;
        itemElement.textContent = `${item.name} `;

        return itemElement;
      },
      setUploadImageAttribute() {
        if (this.uploadImage) {
          this.editorConfig.toolbar.items.push("uploadImage");
        }
      },
    },
  };
</script>

<style>
  .ck-editor__editable_inline {
    min-height: 400px;
  }
  .ck-content p {
    margin: 0 !important;
  }
  .ck.ck-list__item .ck-button {
    display: block;
  }
</style>
