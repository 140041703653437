<template>
  <div class="jira-helper-plugin">
    <h1 class="jira-helper-title" v-html="title"></h1>

    <slot name="description"></slot>

    <form @submit.prevent="createTask">
      <div class="jira-helper">
        <div class="jira-plugin-flex jira-plugin-justify-between jira-plugin-w-full">
          <select required v-model="issueType" style="width: 225px">
            <option disabled value="">Type</option>
            <option v-for="issueType in issueTypes" v-bind:value="issueType" :key="issueType">
              {{ issueType }}
            </option>
          </select>

          <input
            required
            type="text"
            minlength="1"
            maxlength="200"
            placeholder="Summary"
            class="jira-helper-summary-input"
            v-model="summary"
          />
        </div>

        <div class="jira-helper-additional-fields-wrapper">
          <slot name="before-description" :fields="fields" />
        </div>

        <div v-if="descriptionLabel.length > 0" v-html="descriptionLabel"></div>
        <textarea
          :placeholder="descriptionPlaceholder"
          class="jira-helper-textarea"
          v-model="description"
        />

        <div class="jira-helper-additional-fields-wrapper">
          <slot name="after-description" :fields="fields" />
        </div>

        <div class="jira-helper-attachment-container">
          <div style="background-color: #06254b" class="jira-helper-button">
            <label for="jira-helper-file-upload">Add Attachment(s)</label>
            <input
              ref="file"
              id="jira-helper-file-upload"
              type="file"
              @change="onUploadFiles"
              multiple
            />
          </div>
        </div>

        <div class="jira-helper-attachment-list">
          <p class="jira-plugin-text-muted" v-if="attachments.length === 0">No file selected...</p>
          <div v-for="attachment in attachments" :key="attachment.name">
            <div>
              {{ attachment.name }}
              <span
                @click="() => removeAttachment(attachment.name)"
                class="jira-helper-remove-attachment"
              >
                &#10005;
              </span>
            </div>
          </div>
        </div>

        <div class="jira-plugin-text-right jira-plugin-font-bold" v-if="jiraStatus !== ''">
          <p v-html="jiraStatus"></p>
        </div>

        <div class="jira-helper-submit-button">
          <input
            :disabled="!disabled || submitting"
            class="jira-helper-button"
            style="background-color: #008a00"
            type="submit"
            :value="submitting ? 'Please wait...' : 'Create Issue'"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { defaultSchema } from "@atlaskit/adf-schema/schema-default";
  import { JSONTransformer } from "@atlaskit/editor-json-transformer";
  import { MarkdownTransformer } from "@atlaskit/editor-markdown-transformer";
  import { Converter } from "showdown";

  export default {
    name: "JiraIssueHelper",
    props: {
      projectKey: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
      },
      currentUser: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        default: "Report an Issue/Request a Feature",
      },
      descriptionLabel: {
        type: String,
        default: "",
      },
      descriptionPlaceholder: {
        type: String,
        default: "Please type your issue or feature request here.",
      },
      issueTypes: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          ["bold", "italic"],
        ],
        jiraStatus: "",
        summary: null,
        description: null,
        issueType: "",
        attachments: [],
        fields: {},
        submitting: false,
      };
    },
    watch: {
      issueType(newValue) {
        this.$emit("issueTypeChange", newValue);
      },
    },
    computed: {
      disabled() {
        return this.issueType && this.description && this.summary && this.summary.length > 1;
      },
      currentRoute() {
        return window.location.href;
      },
    },
    methods: {
      removeAttachment(name) {
        const fileListArr = Array.from(this.attachments);

        this.attachments = fileListArr.filter((f) => f.name !== name);
      },
      onUploadFiles(e) {
        this.attachments = [...this.attachments, ...e.target.files];
      },
      formatDescription() {
        const jsonTransformer = new JSONTransformer();
        const markdownTransformer = new MarkdownTransformer(defaultSchema);
        const converter = new Converter();

        let newDescription = `${this.description}<hr/>
<h3>Reporter Information</h3>
<p>Reporter User ID: ${this.currentUser.id}</p>
<p>Reporter User: ${this.currentUser.firstName} ${this.currentUser.lastName}, ${this.currentUser.email}</p><hr/>`;

        for (const objectKey in this.fields) {
          if (this.fields[objectKey]) {
            newDescription += `<h3>${objectKey}</h3><p>${this.fields[objectKey]}</p><hr/>`;
          }
        }

        newDescription += `<h3>Additional Information</h3>`;
        newDescription += `<p>Current Route: ${this.currentRoute}</p>`;

        let markdownDocument = converter.makeMarkdown(newDescription);

        markdownDocument = markdownDocument.replaceAll("<!-- -->", "");
        markdownDocument = markdownDocument.replaceAll('<span class="ql-cursor"></span>', "");
        markdownDocument = markdownDocument.replaceAll("<br>", "");

        return jsonTransformer.encode(markdownTransformer.parse(markdownDocument));
      },
      async createTask() {
        this.submitting = true;
        this.jiraStatus = "";

        const description = this.formatDescription();
        const formData = new FormData();

        for (let i = 0; i < this.attachments.length; i++) {
          const file = this.attachments[i];

          formData.append(`files[${i}]`, file);
        }

        formData.append("project_key", this.projectKey);
        formData.append("title", this.summary);
        formData.append("description", JSON.stringify(description));
        formData.append("issue_type", this.issueType);

        this.$emit("submitForm", formData);
      },
      toggleLoading() {
        this.submitting = !this.submitting;
      },
      updateStatus(status, success = true) {
        if (success) {
          this.jiraStatus = `<p class='jira-plugin-text-success'>${status}</p>`;
        } else {
          this.jiraStatus = `<p class='jira-plugin-text-error'>${status}</p>`;
        }
      },
      resetForm() {
        this.jiraStatus = "";
        this.summary = null;
        this.description = null;
        this.issueType = "";
        this.attachments = [];
        Object.keys(this.fields).forEach((field) => {
          this.fields[field] = null;
        });
      },
    },
    mounted() {
      this.resetForm();
    },
  };
</script>
<style>
  .jira-plugin-w-full {
    width: 100%;
  }
  .jira-plugin-text-right {
    text-align: right;
  }
  .jira-plugin-font-bold {
    font-weight: bold;
  }
  .jira-plugin-flex {
    display: flex;
  }
  .jira-plugin-justify-between {
    justify-content: space-between;
  }
  .jira-plugin-text-muted {
    color: #c2c2c2;
  }
  .jira-helper {
    font-family: inherit;
  }
  .jira-plugin-text-error {
    color: #ff0000;
  }
  .jira-plugin-text-success {
    color: #187618;
  }
  .jira-helper-plugin {
    padding: 30px;
  }
  h1.jira-helper-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .jira-helper-vue-editor-input div {
    border: none !important;
  }
  .jira-helper-summary-input {
    width: 100%;
    margin-left: 15px;
  }
  .jira-helper-remove-attachment {
    cursor: pointer;
    color: red;
  }
  .jira-helper-textarea {
    margin: 0 0 15px 0;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 300px;
    padding: 20px;
  }
  .jira-helper #quill-container {
    height: 200px;
    font-size: 1rem;
    font-family: inherit;
  }
  .jira-helper-button {
    font-family: inherit;
    display: inline-block;
    margin-top: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 1rem;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
  }
  .jira-helper-submit-button {
    display: flex;
    justify-content: flex-end;
  }
  .jira-helper input[type="text"],
  select {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    padding: 12px 10px;
    font-size: 0.95rem;
    margin-bottom: 14px;
    display: inline-block;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
  }
  .jira-helper select {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent
      url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
      no-repeat;
    background-position-x: 95%;
    background-position-y: 10px;
  }
  .jira-helper input[type="submit"] {
    margin-top: 10px;
    font-size: 1rem;
    cursor: pointer;
    padding: 12px 30px;
    border-radius: 4px;
    color: white;
    border: none;
  }
  .jira-helper input[type="submit"]:disabled {
    opacity: 45%;
  }
  #jira-helper-file-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .jira-helper-attachment-list {
    margin-top: 15px;
  }
</style>
