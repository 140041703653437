export default [
  {
    path: "/reporting",
    component: () =>
      import(
        /* webpackChunkName: "reporting" */
        "../../../views/pages/reporting/Reporting.vue"
      ),
    children: [
      {
        path: "/am-report-card",
        name: "reporting.am-report-card",
        component: () => import("../../../views/pages/reporting/am-report-card/AMReportCard.vue"),
        meta: {
          title: "Account Manager Report Card",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "/primary-goals",
        name: "reporting.client-primary-goals",
        component: () =>
          import("../../../views/pages/reporting/client-primary-goals/ClientPrimaryGoals.vue"),
        meta: {
          title: "Client Primary Goals",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
          clientAccessible: true,
        },
      },
      {
        path: "/cancellation-report",
        name: "reporting.client-cancellation-report",
        component: () =>
          import(
            /* webpackChunkName: "clients.cancellation-report" */
            "../../../views/pages/clients/reporting/cancellation-report/CancellationReport.vue"
          ),
        meta: {
          title: "Cancellation Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "client-sales",
        name: "reporting.client_sales",
        component: () =>
          import(
            /* webpackChunkName: "reporting.client_sales" */
            "../../../views/pages/reporting/client-sales/ClientSales.vue"
          ),
        meta: {
          title: "Sales Data Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "group-performance",
        name: "reporting.group_performance",
        component: () =>
          import(
            /* webpackChunkName: "reporting.group_performance" */
            "../../../views/pages/reporting/group-performance/GroupPerformance.vue"
          ),
        meta: {
          title: "Group Performance",
          requiresAuth: true,
          clientAccessible: true,
        },
      },
      {
        path: "group-report",
        name: "reporting.group_report",
        component: () =>
          import(
            /* webpackChunkName: "reporting.group_report" */
            "../../../views/pages/reporting/group-report/GroupReport.vue"
          ),
        meta: {
          title: "Group Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
          clientAccessible: true,
        },
      },
      {
        path: "service-utilization-report",
        name: "reporting.service_utilization_report",
        component: () =>
          import(
            /* webpackChunkName: "reporting.service_utilization_report" */
            "../../../views/pages/reporting/service-utilization-report/ServiceUtilizationReport.vue"
          ),
        meta: {
          title: "Service Utilization Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "/reporting/spend-audit-report-v2",
        name: "report.spend-audit-v2",
        component: () =>
          import("../../../views/pages/reporting/spend-audit-v2/SpendAuditReportIndex.vue"),
        meta: {
          title: "Pacing Report (Spend Audit Report)",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "reporting/activities",
        name: "report.activities",
        component: () =>
          import("../../../views/pages/reporting/client-activity/ClientActivityReport.vue"),
        meta: {
          title: "Activity Reporting",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "co-op",
        component: () => import("../../../views/pages/reporting/co-op/CoOp.vue"),
        children: [
          {
            path: "",
            name: "co-op.hub",
            component: () => import("../../../views/pages/reporting/co-op/index/co-op-hub.vue"),
            meta: {
              title: "Co-Op Reports",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "seo",
            name: "co-op.seo",
            component: () =>
              import("../../../views/pages/reporting/co-op/co-op-seo/ReportCoopSeo.vue"),
            meta: {
              title: "Co-Op SEO Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "sem",
            name: "co-op.sem",
            component: () =>
              import("../../../views/pages/reporting/co-op/co-op-keyword/ReportCoopKeyword.vue"),
            meta: {
              title: "Co-Op SEM Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "facebook",
            name: "co-op.facebook",
            component: () =>
              import("../../../views/pages/reporting/co-op/co-op-facebook/ReportCoopFacebook.vue"),
            meta: {
              title: "Co-Op Facebook Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "dv360",
            name: "co-op.dv360",
            component: () =>
              import("../../../views/pages/reporting/co-op/co-op-dv360/ReportCoopDV360.vue"),
            meta: {
              title: "Co-Op Display & Video Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
        ],
      },
      {
        path: "co-op-reports",
        component: () => import("../../../views/pages/reporting/co-op/CoOp.vue"),
        children: [
          {
            path: "",
            name: "co-op.report-tracker",
            component: () =>
              import("../../../views/pages/reporting/co-op/tracker/CoopReportTracker.vue"),
            meta: {
              title: "Co-Op Report Tracker",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
        ],
      },
      {
        path: "client-login",
        name: "report.client.index",
        component: () =>
          import("../../../views/pages/reporting/client-login/ClientLoginReport.vue"),
        meta: {
          title: "Client Login Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "email-analytics",
        name: "report.email_analytics",
        component: () =>
          import("../../../views/pages/reporting/email-analytics/EmailAnalytics.vue"),
        meta: {
          title: "Email Analytics",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "fca",
        component: () => import("../../../views/pages/reporting/fca/FCA.vue"),
        children: [
          {
            path: "",
            name: "fca.hub",
            component: () => import("../../../views/pages/reporting/fca/index/FCAHub.vue"),
            meta: {
              title: "FCA Tools & Reports",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "audit-v2",
            name: "fca.auditV2",
            component: () => import("../../../views/pages/reporting/fca/audit-v2/AuditReport.vue"),
            meta: {
              title: "FCA Audit",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "heavy-up",
            component: () => import("../../../views/pages/reporting/fca/heavy-up/HeavyUp.vue"),
            children: [
              {
                path: "tracker",
                name: "fca.heavy_up.tracker",
                component: () =>
                  import("../../../views/pages/reporting/fca/heavy-up/tracker/HeavyUpTracker.vue"),
                meta: {
                  title: "Heavy Up Tracker",
                  requiresAuth: true,
                  searchable: true,
                  searchGroup: "Reporting",
                },
              },
              {
                path: "campaigns",
                name: "fca.heavy-up.campaigns",
                component: () =>
                  import(
                    "../../../views/pages/reporting/fca/heavy-up/campaigns/HeavyUpCampaigns.vue"
                  ),
                meta: {
                  title: "Heavy Up Campaigns",
                  requiresAuth: true,
                  searchable: true,
                  searchGroup: "Reporting",
                },
              },
            ],
          },
          {
            path: "actual-spend",
            name: "fca.actual_spend",
            component: () =>
              import("../../../views/pages/reporting/fca/actual-spend/ActualSpend.vue"),
            meta: {
              title: "FCA Actual Spend",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "oem-budgets",
            name: "fca.oem_budgets.index",
            component: () =>
              import("../../../views/pages/reporting/fca/oem_budgets/index/OemBudgetsPage.vue"),
            meta: {
              title: "OEM Budgets",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "oem-budgets/:clientId/edit",
            name: "fca.oem_budgets.edit",
            component: () =>
              import("../../../views/pages/reporting/fca/oem_budgets/edit/OemBudgetsEditPage.vue"),
            meta: {
              title: "Edit OEM Budgets",
              requiresAuth: true,
            },
          },
          {
            path: "vsd",
            component: () => import("../../../views/pages/reporting/fca/vsd/VSD.vue"),
            children: [
              {
                path: "tracker",
                name: "fca.vsd.tracker",
                component: () =>
                  import("../../../views/pages/reporting/fca/vsd/tracker/VSDTracker.vue"),
                meta: {
                  title: "BCDF Tracker",
                  requiresAuth: true,
                  searchable: true,
                  searchGroup: "Reporting",
                },
              },
              {
                path: "campaigns",
                name: "fca.vsd.campaigns",
                component: () =>
                  import("../../../views/pages/reporting/fca/vsd/campaigns/VSDCampaigns.vue"),
                meta: {
                  title: "BCDF Campaigns",
                  requiresAuth: true,
                  searchable: true,
                  searchGroup: "Reporting",
                },
              },
            ],
          },
          {
            path: "vsd-report",
            name: "fca.vsd.report",
            component: () =>
              import(
                /* webpackChunkName: "fca.vsd.report" */
                "../../../views/pages/reporting/fca/vsd/report/VSDReport.vue"
              ),
            meta: {
              title: "BCDF Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
              clientAccessible: true,
            },
          },
          {
            path: "download-report",
            name: "fca.report.download",
            component: () =>
              import("../../../views/pages/reporting/fca/reports/DownloadReports.vue"),
            meta: {
              title: "Download Stellantis Reports",
              requiresAuth: true,
              searchable: true,
              searchGroup: "FCA",
            },
          },
        ],
      },
      {
        path: "subaru",
        component: () => import("../../../views/pages/reporting/subaru/Subaru.vue"),
        children: [
          {
            path: "",
            name: "subaru.hub",
            component: () => import("../../../views/pages/reporting/subaru/index/SubaruHub.vue"),
            meta: {
              title: "Subaru Tools & Reports",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "audit",
            name: "subaru.audit.tool",
            component: () => import("../../../views/pages/reporting/subaru/audit/AuditReport.vue"),
            meta: {
              title: "Subaru Audit Tool",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "billing",
            name: "subaru.billing.report",
            component: () =>
              import("../../../views/pages/reporting/subaru/billing/BillingReport.vue"),
            meta: {
              title: "Subaru Billing Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "download-report",
            name: "subaru.report.download",
            component: () =>
              import("../../../views/pages/reporting/subaru/reports/DownloadReports.vue"),
            meta: {
              title: "Download Subaru Reports",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Subaru",
            },
          },
        ],
      },
      {
        path: "ga4-event-manager",
        name: "reporting.ga4-event-manager",
        component: () =>
          import("../../../views/pages/reporting/google-analytics4/EventManager.vue"),
        meta: {
          title: "Google Analytics 4 Event (Goal) Manager",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "strategies",
        name: "reporting.client-strategies",
        component: () =>
          import("../../../views/pages/reporting/client-strategies/ClientStrategies.vue"),
        meta: {
          title: "Client Strategies",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
          clientAccessible: true,
        },
      },
      {
        path: "/bandwidth-report",
        component: () => import("../../../views/pages/reporting/bandwidth/BandwidthReport.vue"),
        children: [
          {
            path: "",
            name: "bandwidth.index",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.index" */
                "../../../views/pages/reporting/bandwidth/index/BandwidthReportIndex.vue"
              ),
            meta: {
              title: "Bandwidth Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "account-management",
            name: "bandwidth.account-management",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.account-management" */
                "../../../views/pages/reporting/bandwidth/account-management/AccountManagementBandwidth.vue"
              ),
            meta: {
              title: "Account Management Bandwidth",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "premium",
            name: "bandwidth.dv360",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.dv360" */
                "../../../views/pages/reporting/bandwidth/dv360/Dv360Bandwidth.vue"
              ),
            meta: {
              title: "Premium Bandwidth",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "seo",
            name: "bandwidth.seo",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.seo" */
                "../../../views/pages/reporting/bandwidth/seo/SeoBandwidth.vue"
              ),
            meta: {
              title: "SEO Bandwidth",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "social",
            name: "bandwidth.social",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.seo" */
                "../../../views/pages/reporting/bandwidth/social/SocialBandwidth.vue"
              ),
            meta: {
              title: "Social Bandwidth",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
          {
            path: "sem",
            name: "bandwidth.sem",
            component: () =>
              import(
                /* webpackChunkName: "bandwidth.sem" */
                "../../../views/pages/reporting/bandwidth/sem/SemBandwidth.vue"
              ),
            meta: {
              title: "SEM Bandwidth",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Reporting",
            },
          },
        ],
      },
      {
        path: "overwatch-dashboard",
        name: "reporting.overwatch-dashboard",
        component: () =>
          /* webpackChunkName: "reporting.overwatch-dashboard" */
          import("../../../views/pages/reporting/overwatch-dashboard/OverwatchDashboard.vue"),
        meta: {
          title: "Overwatch Dashboard",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "rollover-report",
        name: "reporting.rollover-report",
        component: () =>
          /* webpackChunkName: "reporting.rollover-report" */
          import("../../../views/pages/reporting/rollover-report/RolloverReport.vue"),
        meta: {
          title: "Rollover Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "seo-strategy-deliverables",
        name: "reporting.seo-strategy-index",
        component: () =>
          import("../../../views/pages/reporting/seo-strategy/deliverables/Deliverables.vue"),
        meta: {
          title: "SEO Strategies",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "seo-deliverable-pages",
        name: "reporting.seo-deliverable-pages",
        component: () =>
          import(
            "../../../views/pages/reporting/seo-strategy/deliverable-pages/DeliverablePages.vue"
          ),
        meta: {
          title: "SEO Landing Pages",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "seo-achievements",
        name: "reporting.seo-achievement-index",
        component: () =>
          import("../../../views/pages/reporting/seo-strategy/achievements/AchievementsView.vue"),
        meta: {
          title: "SEO Achievements",
          requiresAuth: true,
        },
      },
      {
        name: "reporting.lead_report",
        path: "leads",
        component: () => import("../../../views/pages/reporting/leads-report/LeadsReport.vue"),
        meta: {
          title: "Leads Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "sem-configuration-report",
        name: "reporting.sem-configuration-report",
        component: () =>
          import(
            "../../../views/pages/reporting/sem-configuration-report/SemConfigurationReport.vue"
          ),
        meta: {
          title: "SEM Configuration Report",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
        },
      },
      {
        path: "sem-budget-recommendations",
        name: "reporting.sem-budget-recommendations",
        component: () =>
          import(
            /* webpackChunkName: "sem-budget-recommendations" */
            "../../../views/pages/reporting/sem-budget-recommendation/SEMBudgetRecommendation.vue"
          ),
        meta: {
          title: "SEM Budget Recommendations",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Reporting",
          clientAccessible: true,
        },
      },
    ],
  },
];
