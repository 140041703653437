import { render, staticRenderFns } from "./IssueHelper.vue?vue&type=template&id=39df5e00&scoped=true"
import script from "./IssueHelper.vue?vue&type=script&lang=js"
export * from "./IssueHelper.vue?vue&type=script&lang=js"
import style0 from "./IssueHelper.vue?vue&type=style&index=0&id=39df5e00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39df5e00",
  null
  
)

export default component.exports