<template>
  <v-menu
    open-on-hover
    :close-on-click="false"
    bottom
    offset-y
    nudge-left="100"
    z-index="100"
    rounded
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="inline">
        <v-btn class="mr-1" v-bind="attrs" v-on="on" icon color="secondary">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list>
      <div class="grid grid-cols-2 gap-8 p-6">
        <div
          class="flex flex-col align-center"
          v-for="(item, index) in navigationItems"
          :key="index"
        >
          <template v-if="item.route">
            <router-link
              :to="{ name: item.route }"
              target="_blank"
              class="flex flex-col items-center"
            >
              <v-btn :color="item.color" fab class="rounded mb-1">
                <span class="font-bold text-xl">{{ item.name[0] }}</span>
              </v-btn>
              <div class="text-sm text-gray-600 text-center">{{ item.name }}</div>
            </router-link>
          </template>
          <template v-else>
            <a :href="item.link" target="_blank" class="flex flex-col items-center">
              <v-btn :color="item.color" fab class="rounded mb-1">
                <span class="font-bold text-xl">{{ item.name[0] }}</span>
              </v-btn>
              <div class="text-sm text-gray-600 text-center">{{ item.name }}</div>
            </a>
          </template>
        </div>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "AppSwitcherBar",
    computed: {
      navigationItems() {
        const currentUser = this.$store.getters["authorization/currentUser"];

        return [
          { name: "TaskIQ", color: "primary darken-4 white--text", route: "taskiq.login" },
          {
            name: "Mystery Shop",
            color: "success",
            link: `${process.env.VUE_APP_MYSTERY_SHOP_CLIENT_PROXY}/auth/token/${currentUser?.kobiKey}`,
          },
          { name: "Feed Transformer", color: "info", link: "https://feeds.clickheredigital.com" },
          {
            name: "Campaign Creator",
            color: "warning",
            link: "https://campaigns.clickheredigital.com",
          },
          { name: "SEO Tools", color: "error", link: "https://seo.clickhereiq.io/" },
          {
            name: "Intra Web",
            color: "primary",
            link: "https://sites.google.com/clickherepublishing.com/chp/",
          },
        ];
      },
    },
  };
</script>
