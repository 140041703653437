import "vue-toastification/dist/index.css";
import "vue-advanced-cropper/dist/style.css";
import "./assets/scss/index.scss";
import "./assets/styles/index.scss";

import * as Sentry from "@sentry/vue";
import HighchartsVue from "highcharts-vue";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import { io } from "socket.io-client";
import Vue from "vue";
import { Cropper } from "vue-advanced-cropper";
import VueAnalytics from "vue-analytics";
import VueSocketIOExt from "vue-socket.io-extended";
import Toast from "vue-toastification";
import Vuelidate from "vuelidate";

import App from "./App.vue";
import { ignoredErrors } from "./helpers/error";
import calculations from "./mixins/calc";
import formatters from "./mixins/formatters";
import helpers from "./mixins/helpers";
import mapping from "./mixins/mapping";
import notificationMixin from "./mixins/notificationMixin";
import validationMixin from "./mixins/validationMixin";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://0110144d94584287b5d40d4658e446e0@o4504129896448000.ingest.sentry.io/4504130457960448",
    ignoreErrors: [...ignoredErrors],
    beforeSend: (event, hint) => {
      if (hint && hint.originalException) {
        const { response } = hint.originalException;

        if (response && response.status && response.status === 401) {
          return null;
        }
      }

      return event;
    },
  });

  Vue.use(VueAnalytics, {
    id: "UA-18855524-11",
    router,
  });
}

Vue.use(HighchartsVue);

const requireComponent = require.context(
  // The relative path of the components folder
  "./components",

  // Whether to look in sub folders
  true,

  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,

    /*
     * Look for the component options on `.default`, which will
     * exist if the component was exported with `export default`,
     * otherwise fall back to module's root.
     */
    componentConfig.default || componentConfig
  );
});

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(Toast, {});
Vue.use(Cropper);
Vue.prototype.heap = window.heap;

Vue.use(
  VueSocketIOExt,
  io(process.env.VUE_APP_WEB_SOCKET_URL, {
    autoConnect: false,
    auth: (cb) => {
      cb({ token: store.getters["authorization/currentUserAccessToken"] });
    },
    transports: ["websocket"],
  })
);

Vue.mixin(formatters);
Vue.mixin(helpers);
Vue.mixin(mapping);
Vue.mixin(calculations);
Vue.mixin(notificationMixin);
Vue.mixin(validationMixin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
